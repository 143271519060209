<template>
  <b-card class="bg-primary banner" no-body v-if="formacode">
    <div class="banner__body">
      <div class="banner__title">
        <span
          class="tw-block tw-text-white/80 banner__label banner__label--opaque"
        >
          Gift Card Applied
        </span>
        <span class="tw-block tw-text-white banner__heading">
          {{ formacode }}
        </span>
      </div>

      <div class="banner__subtitle">
        <span class="banner__label">
          Enjoy {{ toUsCurrency(formacodeDiscount) }} off your treatment plan
        </span>

        <span class="banner__label banner__label--opaque">
          Discount applied at checkout
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
// utils
import { toUsCurrency, toNumber } from "@/utils/number";
/**
 * Sample Forma Code: 42424-<amount in cents>
 */
export default {
  computed: {
    ...mapState("onboarding", ["onboardingPatient"]),

    formacodeDiscount() {
      return toNumber(this.onboardingPatient?.formaCodeDiscountAmount);
    },

    formacode() {
      return this.onboardingPatient?.formaCodeDiscountApplied;
    },
  },
  methods: {
    toUsCurrency,
  },
};
</script>

<style lang="scss">
.banner {
  color: white;
  padding-inline: 1.5rem;
  padding-block: 0.75rem;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  &__title {
    flex-shrink: 0;
  }

  &__subtitle {
    width: 100%;
    flex-grow: 1;

    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }

  &__label {
    color: white;
    display: block;

    &--opaque {
      opacity: 0.8;
    }
  }

  &__heading {
    font-family: "hatton-medium";
    font-size: 1rem;
    display: block;
  }
}
</style>
