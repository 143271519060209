var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("OnboardingProgress", {
        attrs: { hide: _vm.hideBackButton, progress: _vm.progress },
        on: { back: _vm.prev },
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: { hide: _vm.hideBackButton, title: _vm.stepTitle },
            on: { back: _vm.prev },
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: { cols: "12", lg: "5", xl: "4", xxl: "4" },
            },
            [
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v(" Onboarding Complete ")]
                    ),
                  ]),
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: {
                  value: _vm.mobileProgressDisplay,
                  max: 100,
                  variant: "purple",
                  height: "5px",
                },
              }),
              _vm.step !== "complete"
                ? _c(
                    "h3",
                    {
                      staticClass:
                        "d-lg-none mt-4 pb-4 mb-5 bold d-letter-spacing onboarding-title",
                    },
                    [
                      _vm.step == "summary"
                        ? [_vm._v("Visit Summary")]
                        : _vm._e(),
                      _vm.step == "shipping-info"
                        ? [_vm._v("Shipping Information")]
                        : _vm._e(),
                      _vm.step == "billing"
                        ? [_vm._v("Billing Information")]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c("DiscountBanner", {
                attrs: { show: _vm.coupon.show, couponid: _vm.coupon.couponid },
              }),
              _c("OnboardingCheckoutFormaCodeDiscountBanner"),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _vm.step == "summary"
                ? _c("Summary", {
                    ref: "Summary",
                    attrs: {
                      "analytics-id": _vm.analyticsId,
                      body: _vm.onboardingData,
                      buttonDisabled: _vm.buttonDisabled,
                      saving: _vm.buttonDisabled,
                      nonrx: _vm.nonrx,
                      selectedPharmacy: _vm.selectedPharmacy,
                      pharmacy: _vm.pharmacy,
                      onboardingflow: _vm.onboardingflow,
                    },
                    on: {
                      nextStep: _vm.submitPharmacy,
                      clearPharmacy: _vm.clearPharmacy,
                      "reload-summary": _vm.getOnboarding,
                      goToStep: _vm.goToStep,
                    },
                  })
                : _vm._e(),
              _vm.step == "pharmacy-select"
                ? _c("PharmacySelect", {
                    on: {
                      alert: _vm.showAlert,
                      hideAlert: _vm.hideAlert,
                      submitAnswer: _vm.submitPharmacySelection,
                    },
                  })
                : _vm._e(),
              _vm.step == "shipping-info"
                ? _c("Shipping", {
                    ref: "Shipping",
                    attrs: {
                      "analytics-id": _vm.analyticsId,
                      buttonDisabled: _vm.buttonDisabled,
                      saving: _vm.buttonDisabled,
                      body: _vm.onboardingData,
                      nonrx: _vm.nonrx,
                      onboardingflow: _vm.onboardingflow,
                    },
                    on: {
                      setButtonDisable: _vm.setButtonDisable,
                      nextStep: _vm.submitShipping,
                      showAlert: _vm.showAlert,
                    },
                  })
                : _vm._e(),
              _vm.step == "billing"
                ? _c("BillingDisplay", {
                    ref: "BillingDisplay",
                    attrs: {
                      body: _vm.onboardingData,
                      nonrx: _vm.nonrx,
                      discount: _vm.discount,
                      onboardingflow: _vm.onboardingflow,
                      getOnboarding: _vm.getOnboarding,
                      "analytics-id": _vm.analyticsId,
                    },
                    on: {
                      "set-discount": _vm.setDiscount,
                      "set-total": _vm.setTotal,
                    },
                  })
                : _vm._e(),
              _vm.step == "billing"
                ? _c("BillingInput", {
                    attrs: {
                      body: _vm.onboardingData,
                      disabled: _vm.buttonDisabled,
                      nonrx: _vm.nonrx,
                      "total-price": _vm.total,
                    },
                    on: {
                      showAlert: _vm.showAlert,
                      hideAlert: _vm.hideAlert,
                      nextStep: _vm.submitBilling,
                      "update-onboarding": _vm.getOnboarding,
                    },
                  })
                : _vm._e(),
              _vm.step == "complete"
                ? _c("Complete", {
                    attrs: {
                      "analytics-id": _vm.analyticsId,
                      nonrx: _vm.nonrx,
                      body: _vm.onboardingData,
                      totalPrice: _vm.total,
                      couponid: _vm.coupon.couponid,
                    },
                  })
                : _vm._e(),
              _vm.step == "upsell"
                ? _c("AltUpsell", { attrs: { couponid: _vm.coupon.couponid } })
                : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", lg: "3", xl: "4", xxl: "4" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }