<template>
  <b-row>
    <OneClickUpsell
      v-if="isUpsellModalVisible"
      :isUpsellModalVisible.sync="isUpsellModalVisible"
      upsell-from="Onboarding Upsell"
      :couponid="couponid"
    />
  </b-row>
</template>

<script>
import OneClickUpsell from "@/components/onboarding/checkout/OneClickUpsell";

export default {
  name: "AltUpsell",
  components: {
    OneClickUpsell,
  },
  props: {
    couponid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isUpsellModalVisible: true,
    };
  },
  watch: {
    isUpsellModalVisible() {
      if (!this.isUpsellModalVisible) {
        this.$router.push("/identity-verification");
      }
    },
  },
  mounted() {
    this.isUpsellModalVisible = true;
  },
};
</script>

<style></style>
