var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formacode
    ? _c(
        "b-card",
        { staticClass: "bg-primary banner", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "banner__body" }, [
            _c("div", { staticClass: "banner__title" }, [
              _c(
                "span",
                {
                  staticClass:
                    "tw-block tw-text-white/80 banner__label banner__label--opaque",
                },
                [_vm._v(" Gift Card Applied ")]
              ),
              _c(
                "span",
                { staticClass: "tw-block tw-text-white banner__heading" },
                [_vm._v(" " + _vm._s(_vm.formacode) + " ")]
              ),
            ]),
            _c("div", { staticClass: "banner__subtitle" }, [
              _c("span", { staticClass: "banner__label" }, [
                _vm._v(
                  " Enjoy " +
                    _vm._s(_vm.toUsCurrency(_vm.formacodeDiscount)) +
                    " off your treatment plan "
                ),
              ]),
              _c(
                "span",
                { staticClass: "banner__label banner__label--opaque" },
                [_vm._v(" Discount applied at checkout ")]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }