<template>
  <div class="billing-display">
    <!-- Credit Card Modal -->
    <CreditModal
      :show="showModal"
      @exit="hideModal()"
      header-class="id-modal"
      body-class="id-body-modal"
    >
      <template #header>
        <img :src="require('@/assets/images/credit.svg')" alt="" />
        <b-row class="security-row mt-5 pt-3">
          <b-col cols="12">
            <b-icon icon="lock-fill"></b-icon> 256-BIT TLS SECURITY
          </b-col>
        </b-row>
      </template>

      <template #body>
        <h3
          class="sub-text text-uppercase text-primary"
          style="letter-spacing: 0.16em"
        >
          BILLING INFORMATION
        </h3>

        <h2 class="heading h1 text-default mt-5 mb-2 initial-line-height">
          Why do you need my credit card information?
        </h2>
        <p class="sub-text">
          Winona’s physicians are on hand to review your medical information
          quickly in order to provide you with safe, accessible care so you can
          feel better right away.
        </p>
        <p class="sub-text">
          We ask that you share your credit card information so that if the
          medication is approved by one of our doctors we can get it to you
          right away and to ensure that our patients are serious about the
          desire to receive treatment.
        </p>
        <p class="sub-text">
          Your card will never be charged until treatment is prescribed.
        </p>
        <h2 class="heading h1 text-default mt-4 mb-2 initial-line-height">
          Shouldn’t I talk to a Winona doctor before purchasing treatment?
        </h2>
        <p class="sub-text">
          Your Winona physician will never prescribe a treatment without first
          reviewing your medical history, and addressing any questions or
          concerns either of you may have.
        </p>
        <p class="sub-text">
          Once you enter your credit card information you will be directed to
          your secure patient portal where you can directly message your doctor.
          If you have any additional questions, you can ask them at this step.
        </p>

        <b-button pill block variant="info" @click="hideModal()" class="mt-5"
          >I understand</b-button
        >
      </template>
    </CreditModal>
    <div class="bg-overlay" @click="hideModal()"></div>

    <h2 class="question-title with-subtext">Your doctor is waiting</h2>
    <p class="sub-text">
      Add a payment method to be used if treatment is prescribed (you will not
      be charged now).
      <!-- TODO: hide modal for billing information for now -->
      <br />
      <span
        style="color: #828282; cursor: pointer"
        class="text-underline"
        @click="openModal()"
        >Why do you need my credit card information?</span
      >
    </p>
    <b-card
      v-if="!nonrx && body.desiredplan"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="label-text mb-5">Your Treatment if Prescribed</h3>
      <b-row
        align-v="center"
        v-for="med in body.desiredplan.filter(
          (x) =>
            x.type === undefined || (x.type != undefined && x.type === 'RX')
        )"
        style="margin-bottom: 1rem"
        :key="med.productid"
      >
        <b-col cols="8">
          <h4 class="label-text mb-1">{{ med.name }}</h4>
          <span class="small-text">{{ med.pricingdetail.nickname }}</span>
        </b-col>
        <b-col cols="4" class="text-right">
          <h3 class="label-text">${{ med.price }}</h3>
        </b-col>
      </b-row>
      <div class="mt-4"></div>
      <hr
        v-if="body.hasOwnProperty('referraldiscountamount') || discountDisplay"
      />
      <b-row v-if="body.referraldiscountamount">
        <b-col cols="8">
          <span class="small-text">First Time Referral Discount</span>
        </b-col>
        <b-col cols="4" class="text-right">
          <span class="small-text"> -${{ body.referraldiscountamount }} </span>
        </b-col>
      </b-row>

      <template v-if="discount > 0">
        <b-row>
          <b-col cols="8">
            <!-- WIN-8178 -->
            <h4 class="label-text mb-1">{{ this.body.couponid }}</h4>
            <span class="small-text" v-if="couponData.percent_off"
              >{{ couponData.percent_off }}% off<span
                v-if="couponData.duration == 'once'"
              >
                of your first purchase</span
              ></span
            >
          </b-col>
          <b-col cols="4" class="text-right">
            <span class="small-text text-info"
              >{{ couponData.percent_off }}% off</span
            >
            <span class="small-text"> -${{ discountDisplay }} </span>
          </b-col>
        </b-row>

        <hr class="my-3" style="border-color: #19191c" />
      </template>

      <b-row>
        <b-col cols="8">
          <span class="small-text">Online doctor visit &amp; shipping</span>
        </b-col>

        <b-col class="text-right">
          <span class="small-text" style="text-decoration: line-through">
            $50.00
          </span>

          <span class="small-text"> Free</span>
        </b-col>

        <template v-if="formacodeDiscount">
          <b-col cols="8"> Giftcard Discount </b-col>

          <b-col class="text-right">
            -{{ toUsCurrency(formacodeDiscount) }}
          </b-col>
        </template>
      </b-row>

      <hr />

      <b-row>
        <b-col cols="6">
          <b>Grand Total</b>
        </b-col>
        <b-col cols="6" class="text-right">
          <b>{{ toUsCurrency(totalamount) }}</b>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="0 && body.pharmacyid != ''"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="mb-5 label-text">Platform Fee</h3>

      <b-row>
        <b-col cols="8"
          ><span class="small-text"
            >Outside Winona Pharmacy Network</span
          ></b-col
        >
        <b-col cols="4" class="text-right"
          ><span class="small-text">${{ platformfee.toFixed(2) }}</span></b-col
        >
      </b-row>
    </b-card>

    <h3 v-if="desiredAddon.length > 0" class="label-text mt-5 pt-2">To Pay</h3>
    <b-card
      v-if="desiredAddon.length > 0"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="mb-5 label-text">Your Order</h3>

      <hr class="my-3" />

      <b-row>
        <b-col cols="8"> Order Sub-total </b-col>
        <b-col cols="4" class="text-right"> ${{ subtotal.toFixed(2) }} </b-col>
      </b-row>
      <hr class="my-3" v-if="0 && body.pharmacyid != ''" />
      <b-row v-if="0 && body.pharmacyid != ''">
        <b-col cols="8"> Platform fee </b-col>
        <b-col cols="4" class="text-right">
          ${{ platformfee.toFixed(2) }}
        </b-col>
      </b-row>
      <hr class="my-3" />
      <b-row>
        <b-col cols="8"> Tax </b-col>
        <b-col cols="4" class="text-right"> ${{ taxamount.toFixed(2) }} </b-col>
      </b-row>

      <hr class="my-3" style="border-color: #19191c; border-width: 3px" />
      <b-row>
        <b-col cols="6">
          <b>Grand Total</b>
        </b-col>
        <b-col cols="6" class="text-right">
          <b>${{ totalamount.toFixed(2) }}</b>
        </b-col>
      </b-row>
    </b-card>

    <div
      class="promo-code-form"
      v-if="isShowPromoCodeForm && !hasAppliedFormaCode"
    >
      <span class="promo-code-form__label"> Enter Giftcard </span>

      <div class="promo-code-form__body">
        <div class="promo-code-form__text-wrapper">
          <input
            type="text"
            v-model="formaCode.id"
            v-mask="FORMA_CODE.FORMAT"
            class=""
            :class="{
              'is-valid': formaCode.isValid,
              'is-invalid': formaCode.isValid === false,
            }"
            :disabled="hasAppliedFormaCode || formaCode.isLoading"
          />

          <IconLoading
            class="promo-code-form__loading-icon"
            v-if="formaCode.isLoading"
          />

          <span
            class="promo-code-form__error-label"
            v-if="formaCode.isValid === false"
          >
            Giftcard not valid
          </span>
        </div>

        <button
          @click="applyCoupon"
          :disabled="
            formaCode.id.length !== FORMA_CODE.FORMAT.length ||
            formaCode.isLoading
          "
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import _ from "lodash";
import { mapState } from "vuex";

// services
import { TransactionService } from "@/services/transaction.service";
import { OnboardingService } from "@/services/onboarding.service";

// components
import CreditModal from "@/components/partials/SideModal";
import IconLoading from "@/components/icon/IconLoading";

// utils
import $cookies from "@/utils/cookies";
import { toUsCurrency, toNumber } from "@/utils/number";

// constants
import { COOKIES, FORMA_CODE } from "@/constants";

export default {
  components: {
    CreditModal,
    IconLoading,
  },
  props: {
    body: Object,
    nonrx: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: Number,
      default: 0,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
    getOnboarding: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      FORMA_CODE,
      formaCode: {
        id: "",
        isValid: null,
        isLoading: false,
      },
      total: 0,
      checkoutData: { checkout: [] },
      desiredAddon: [],
      desiredPlan: [],
      couponData: {},
      orderDetail: {},
      orderSummary: {},
      subtotal: 0,
      plantotal: 0,
      addontotal: 0,
      taxamount: 0,
      taxrate: 0,
      totalamount: 0,
      platformfee: 0, // 50.0,
      showModal: false,
    };
  },
  watch: {
    body() {
      this.calculateTreatmentPlans();
    },
  },
  computed: {
    ...mapState("onboarding", ["onboardingPatient"]),

    formacodeDiscount() {
      return toNumber(this.onboardingPatient?.formaCodeDiscountAmount);
    },

    hasAppliedFormaCode() {
      return !!this.onboardingPatient?.formaCodeDiscountApplied;
    },

    discountDisplay() {
      return (Math.round(this.discount * 100) / 100).toFixed(2);
    },

    isShowPromoCodeForm() {
      return !!$cookies.get(COOKIES.SHOW_PROMO_FORM.COOKIE_NAME);
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.analyticsId,
        screen: "5.3-A",
        onboardingflow: this.onboardingflow,
        firstname: this.body.firstname,
        lastname: this.body.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        ...(await this.$store.dispatch("getVoluumDetails")),
      },
      {
        referrer: await this.$store.dispatch("getEverflowDetails"),
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );

    this.calculateTreatmentPlans();
  },
  methods: {
    _get: _.get,

    toUsCurrency,

    calculateTreatmentPlans() {
      this.checkoutData.checkout = [];
      if (this.body.formaCodeDiscountApplied) {
        this.formaCode.id = this.body.formaCodeDiscountApplied;
        this.formaCode.isValid = true;
      }
      if (this.body.desiredaddon) {
        for (const med of this.body.desiredaddon) {
          this.total = this.total + Math.floor(med.price);
          this.checkoutData.checkout.push({
            productid: med.productid,
            pricingid: med.pricingid,
            quantity: med.quantity,
          });
        }
      }
      // Desired Plan
      if (!this.nonrx && this.body.desiredplan && this.body.desiredplan) {
        for (const med of this.body.desiredplan) {
          this.checkoutData.checkout.push({
            productid: med.productid,
            pricingid: med.pricingdetail.pricingid,
            quantity: med.quantity ? med.quantity : 1,
          });
        }
      }
      this.computeTax();
    },

    openModal() {
      this.elementTag("hidden", true);
    },
    hideModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      // htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    async applyCoupon() {
      try {
        this.formaCode.isLoading = true;

        if (_.isEmpty(this.formaCode.id)) {
          this.formaCode.isValid = null;
          return;
        }

        await OnboardingService.applyCoupon({
          couponid: this.formaCode.id,
        });

        await this.getOnboarding();

        this.computeTax();

        this.formaCode.isValid = true;
      } catch (error) {
        this.formaCode.isValid = false;
      } finally {
        this.formaCode.isLoading = false;
      }
    },

    async computeTax() {
      try {
        const { referraldiscountamount, couponid } = this.body;

        const creditDiscount =
          this.formacodeDiscount + toNumber(referraldiscountamount);

        this.checkoutData.creditdiscount = creditDiscount;
        this.checkoutData.couponid = couponid;

        if (couponid) {
          const couponGet = await OnboardingService.checkCoupon(couponid);

          this.couponData = couponGet.data;
        }

        const res = await TransactionService.computeTax(this.checkoutData);

        if (res && res.data) {
          const { orderdetail, ordersummary } = res.data;
          this.orderSummary = ordersummary;
          this.taxamount = ordersummary.taxamount;
          this.totalamount = ordersummary.totalamount;

          if (ordersummary.discount > 0) {
            this.$emit("set-discount", ordersummary.discount);
          }

          return this.$emit("set-total", this.totalamount);
        }

        const addon = [];
        const desired = [];

        this.desiredAddon = addon;
        this.desiredPlan = desired;

        if (
          !this.nonrx &&
          this.body.desiredplan &&
          this.body.desiredplan.length > 0
        ) {
          const desiredplan = this.body.desiredplan.filter((x) => {
            return (
              x.type === undefined || (x.type != undefined && x.type === "RX")
            );
          });

          for (const record of desiredplan) {
            this.plantotal += Number(record.price);
          }
        }

        if (this.body.desiredaddon && this.body.desiredaddon.length > 0) {
          for (const record of this.body.desiredaddon) {
            this.addontotal += Number(record.price);
          }
        }

        this.subtotal = this.addontotal + this.plantotal || 0;
        this.totalamount =
          this.subtotal + this.taxamount + this.platformfee || 0;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.security-row {
  @include media-breakpoint-down(xs) {
    margin-top: 1.5rem !important;
    padding-top: 0 !important;
  }
}

.id-modal {
  background-color: #f6f6f6;
  height: 45vh;

  @include media-breakpoint-down(xs) {
    height: 30vh;
  }

  img {
    height: 50%;
  }
}

.id-body-modal {
  overflow: auto;
  // height: 55vh !important;
  overflow-x: hidden;

  @include media-breakpoint-down(xs) {
    // height: 70vh !important;
  }
}

.promo-code-form {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
  gap: 1.5rem;

  &__label {
    flex-shrink: 0;
  }

  &__text-wrapper {
    flex-grow: 1;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: 1px solid black;

      &.is-valid {
        border-bottom: 1px solid #22c55e;
      }

      &.is-invalid {
        border-bottom: 1px solid #ef4444;
      }
    }
  }

  &__error-label {
    position: absolute;
    left: 0;
    bottom: -18px;
    color: #ef4444;
    font-size: 12px;
  }

  &__loading-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__body {
    border: 1px solid #edf2f9;
    background-color: rgba(242, 242, 242, 0.5);
    padding: 1rem;
    width: 100%;
    position: relative;
    display: flex;
    gap: 5px;

    button {
      background-color: #a783ff;
      border: 1px solid #a783ff;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      font-family: arial, sans-serif;
      font-size: 14px;
      height: 36px;
      line-height: 27px;
      padding: 0 16px;
      text-align: center;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: pre;
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }
}
</style>
