var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkout-summary" },
    [
      _vm.showModal
        ? _c("OnboardingModal", {
            attrs: { size: "lg", body: _vm.modalBody },
            on: { submitAnswer: _vm.openPharmSelect },
          })
        : _vm._e(),
      _c("SideModal", {
        attrs: {
          show: _vm.showEditModal,
          showClose: true,
          "body-class": "terms-body",
        },
        on: {
          exit: function ($event) {
            _vm.showEditModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h2", { staticClass: "question-title with-subtext" }, [
                  _vm._v(" Edit your prescribed treatments "),
                ]),
                _c("h3", { staticClass: "sub-text mb-6" }, [
                  _vm._v(
                    " Change of mind? Here are the recommended treatments: "
                  ),
                ]),
                _c("EditCheckout", {
                  attrs: {
                    "desired-plan": _vm.desiredPlan,
                    "product-reveal": _vm.productreveal,
                    "treatment-form": _vm.treatmentform,
                  },
                  on: {
                    "close-edit": function ($event) {
                      _vm.showEditModal = false
                    },
                    "update-checkout": function ($event) {
                      _vm.$emit("reload-summary")
                      _vm.showEditModal = false
                    },
                    "get-product-reveal": _vm.getProductReveal,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function ($event) {
            _vm.showEditModal = false
          },
        },
      }),
      _c("SideModal", {
        attrs: {
          show: _vm.riskModal,
          "header-class": "terms-title",
          "body-class": "terms-body",
        },
        on: {
          exit: function ($event) {
            return _vm.hideRiskModal()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "h2 mb-0 heading text-primary" }, [
                  _vm._v("Safety Information"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("Risk"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "text-right", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "min-width": "250px" },
                            attrs: { pill: "", variant: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.agreeTerms()
                              },
                            },
                          },
                          [_vm._v(" I Agree ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function ($event) {
            return _vm.hideRiskModal()
          },
        },
      }),
      !_vm.nonrx &&
      _vm.body.hasOwnProperty("desiredplan") &&
      _vm.desiredPlan.length > 0
        ? _c(
            "div",
            [
              _c("h2", { staticClass: "question-title with-subtext" }, [
                _vm._v("Your visit summary"),
              ]),
              _c("h3", { staticClass: "sub-text mb-5" }, [
                _vm._v(
                  " Almost done! Here’s your personalized treatment plan for the doctor to review. Our board certified doctors are here to determine the right treatment plan for you. In the next step, you’ll add billing information to your account and then a Winona doctor will review your medical history and address any questions. "
                ),
              ]),
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "h3",
                  {
                    staticClass: "sub-text mb-4",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [_vm._v(" Prescription Treatment ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "sub-text mb-4",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#1a6a72" },
                        attrs: {
                          href: "javascript:void(0)",
                          disabled: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            _vm.showEditModal = true
                          },
                        },
                      },
                      [_c("u", [_vm._v("EDIT")])]
                    ),
                  ]
                ),
              ]),
              _vm._l(
                _vm.desiredPlan.filter(
                  (x) =>
                    x.type === undefined ||
                    (x.type != undefined && x.type === "RX")
                ),
                function (med) {
                  return _c(
                    "b-card",
                    {
                      key: med.id,
                      staticClass: "py-2",
                      staticStyle: {
                        "background-color": "rgba(242, 242, 242, 0.5)",
                      },
                      attrs: { "no-body": "" },
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "", "align-v": "stretch" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex flex-column justify-content-between pl-4 pr-2 h-100",
                              attrs: {
                                cols: med.image ? "8" : "12",
                                "align-self": "center",
                              },
                            },
                            [
                              _c("div", { staticClass: "mb-4" }, [
                                _c("h3", { staticClass: "mb-2 med-font" }, [
                                  _vm._v(_vm._s(med.name)),
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(med.pricingdetail.nickname)),
                                ]),
                              ]),
                              _c("p", { staticClass: "med-font mb-0" }, [
                                med.pricingdetail.hasOwnProperty(
                                  "hidebillingfrequency"
                                )
                                  ? _c("span", [
                                      _vm._v(" $" + _vm._s(med.price) + " "),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPricing(
                                              med.pricingdetail.type,
                                              med.price,
                                              med.pricingdetail.recurring
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]),
                            ]
                          ),
                          med.image
                            ? _c(
                                "b-col",
                                { staticClass: "p-0", attrs: { cols: "4" } },
                                [
                                  _c("div", { staticClass: "img-holder" }, [
                                    _c("img", {
                                      attrs: { src: med.image, alt: med.name },
                                    }),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "p",
                {
                  staticClass: "mb-5 pb-3",
                  staticStyle: { "margin-top": "-10px" },
                },
                [
                  _vm._v(
                    " You won't be charged for or shipped this prescription product unless a doctor prescribes it to you. "
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _c("h3", { staticClass: "mt-5 sub-text med-font" }, [
        _vm._v("Added Benefits"),
      ]),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3", md: "auto" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/onboarding/shipping.svg"),
              },
            }),
          ]),
          _c("b-col", { staticClass: "pt-3 pr-0", attrs: { cols: "9" } }, [
            _c("h4", { staticClass: "med-font" }, [_vm._v("Free Shipping")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " We offer fast shipping so you have your medication when you need it. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("b-col", { attrs: { cols: "3", md: "auto" } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/onboarding/support.svg") },
            }),
          ]),
          _c("b-col", { staticClass: "pt-3 pr-0", attrs: { cols: "9" } }, [
            _c("h4", { staticClass: "med-font" }, [
              _vm._v("Continued Support"),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                " As a Winona patient, you will receive continuous, personalized care from our healthcare team who are specially trained to treat your symptoms. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-form-checkbox",
        {
          staticClass: "checkbox-label mt-4",
          attrs: { id: "risk", name: "checkbox-2" },
          model: {
            value: _vm.risk,
            callback: function ($$v) {
              _vm.risk = $$v
            },
            expression: "risk",
          },
        },
        [
          _c("span", [_vm._v("I have read and understand the ")]),
          _c(
            "a",
            {
              staticClass: "text-underline",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.showRiskModal()
                },
              },
            },
            [_vm._v("Safety Information")]
          ),
          _vm._v(" associated with these treatments. "),
        ]
      ),
      _c(
        "b-button",
        {
          staticClass: "mt-4",
          attrs: {
            pill: "",
            block: "",
            variant: "info",
            disabled: _vm.buttonDisabled,
          },
          on: { click: _vm.confirmContinue },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.loading ? "Loading" : !_vm.saving ? "Continue" : "Saving"
              )
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }