var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _vm.isUpsellModalVisible
        ? _c("OneClickUpsell", {
            attrs: {
              isUpsellModalVisible: _vm.isUpsellModalVisible,
              "upsell-from": "Onboarding Upsell",
              couponid: _vm.couponid,
            },
            on: {
              "update:isUpsellModalVisible": function ($event) {
                _vm.isUpsellModalVisible = $event
              },
              "update:is-upsell-modal-visible": function ($event) {
                _vm.isUpsellModalVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }