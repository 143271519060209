<template>
  <div class="pharmacy-select">
    <h2 class="heading">Search your pharmacy</h2>
    <h3>
      If you pick a pharmacy outside of the Winona Pharmacy Network, and qualify
      for a prescription, we will not be able to guarantee the price of the
      medication and provide free monthly delivery.
    </h3>
    <div>
      <label for="pharmName">Pharmacy</label>
      <!-- // TODO add the search icon on the right -->
      <b-form-input
        name="pharmName"
        v-model="name"
        class="pl-0 mb-4"
        placeholder="Pharmacy Name"
      />
      <div role="group" class="mb-3">
        <label class="d-letter-spacing" for="state">State:</label>
        <v-select
          id="state"
          class="select-state-input"
          append-to-body
          :calculate-position="withPopper"
          v-model="state"
          label="text"
          :options="states"
          :searchable="false"
          :reduce="(states) => states.value"
        >
          <template #open-indicator="{ attributes }">
            <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
          </template>
        </v-select>
      </div>
      <label for="city">City</label>
      <b-form-input
        name="city"
        class="pl-0"
        v-model="city"
        placeholder="City"
      ></b-form-input>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            variant="info"
            pill
            class="my-3"
            @click="search"
            :disabled="searchDisabled"
            style="min-width: 300px"
            >Search for a Pharmacy</b-button
          >
        </b-col>
      </b-row>
      <div id="pharmItems" :class="searchedPharmacies ? 'mt-2' : ''">
        <w-checkbox
          type="radio"
          class="pharma-selection"
          v-model="selected"
          v-for="list in searchedPharmacies"
          :key="list.PharmacyId"
          :id="list.PharmacyId.toString()"
          :value="list"
          :label="list.StoreName + ' - ' + list.City"
          :description="list.Address1"
          count="0"
        />
      </div>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            class="mt-3"
            @click="submitPharmacy"
            variant="info"
            pill
            v-if="searchedPharmacies.length > 0"
            >Continue</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { DosespotService } from "@/services/dosespot.service";
import { LookUpService } from "@/services/lookup.service";
import popper from "@/assets/js/popper.js";

export default {
  mixins: [popper],
  data() {
    return {
      name: "",
      state: "",
      states: [],
      city: "",
      searchedPharmacies: [],
      selected: {},
      searchDisabled: false,
    };
  },
  methods: {
    async search() {
      try {
        this.$emit("hideAlert");
        this.searchDisabled = true;
        let { data } = await DosespotService.searchDosespotPharmacy(
          this.name,
          this.state,
          this.city
        );
        this.searchedPharmacies = data;
        this.searchDisabled = false;
        if (this.searchedPharmacies.length == 0) {
          this.$emit(
            "alert",
            "There are 0 search results. Please review your search terms."
          );
        }
      } catch (err) {
        this.searchDisabled = false;
        this.$emit("alert", err);
      }
    },
    submitPharmacy() {
      this.$emit("submitAnswer", this.selected);
    },
  },
  async mounted() {
    const { data } = await LookUpService.getLookUp("state");
    for (let state of data) {
      this.states.push({
        value: state.id,
        text: state.name,
      });
    }
  },
};
</script>

<style>
#pharmItems {
  max-height: 35vh;
  overflow: auto;
}
</style>

<style scoped>
.custom-input-style .vs__dropdown-toggle {
  padding-left: 0;
}
</style>
