<template>
  <div class="shipping-info">
    <!-- Shipping address confirmation modal -->
    <b-modal
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      v-model="showModal"
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2
          class="mb-3 heading color-inherit"
          style="font-size: 26px; line-height: 32px"
        >
          We are unable to verify your address.
        </h2>
        <p style="font-size: 0.8rem">{{ shippingString }}</p>
        <p class="mb-5">Are you sure you want to ship here?</p>
        <b-button
          pill
          block
          variant="info"
          @click="showModal = false"
          :disabled="buttonDisabled"
          >Update Address</b-button
        >
        <b-button
          pill
          block
          variant="outline-secondary"
          @click="submitForm"
          :disabled="buttonDisabled"
          >Yes, it is correct</b-button
        >
      </div>
    </b-modal>

    <b-modal
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      v-model="showUnavailableStateModal"
    >
      <h2
        class="mb-3 heading color-inherit"
        style="font-size: 26px; line-height: 32px"
      >
        Winona is currently unavailable for {{ selectedState.name }}.
      </h2>
      <p>
        Due to state-by-state medical laws, Winona is unable to ship to this
        state as a primary residence.
      </p>
      <p>To continue, please enter residence in one of the following states:</p>
      <ul>
        <div v-for="state in states" :key="state.id">
          <li v-if="state.hasOwnProperty('supported')">{{ state.id }}</li>
        </div>
      </ul>
      <b-row>
        <b-col cols="4" offset="8">
          <b-button
            pill
            block
            variant="outline-secondary"
            @click="showUnavailableStateModal = false"
            >Close</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <h2 class="question-title with-subtext">
      Shipping Information &amp; Address
    </h2>
    <p class="sub-text">
      Please confirm the address of where you would like your Winona products
      shipped. We ship USPS Priority unless otherwise requested.
    </p>
    <b-row class="mt-5" v-if="nonrx">
      <b-col cols="12" md="6">
        <label for="firstname" class="input-label">First Name*</label>
        <b-form-input
          class="fs-mask onboarding-input"
          v-model="user.firstname"
          name="firstname"
          placeholder="First Name"
        />
      </b-col>
      <b-col cols="12" md="6">
        <label for="lastname" class="input-label">Last Name*</label>
        <b-form-input
          class="fs-mask onboarding-input"
          v-model="user.lastname"
          name="lastname"
          placeholder="Last Name"
        />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12" md="12" class="mb-3">
        <label for="address1" class="input-label">Street Address*</label>
        <vue-google-autocomplete
          id="map"
          ref="streetAddress"
          classname="form-control"
          placeholder="Street Address"
          v-on:placechanged="getAddressData"
          :country="['us', 'pr']"
          class="fs-mask onboarding-input"
          @inputChange="onStreetAddressInputChange"
          @change="onStreetAddressChange"
        >
        </vue-google-autocomplete>
      </b-col>
      <b-col cols="12" md="12" class="mb-3">
        <label for="address2" class="input-label">Apartment/Suite</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model.trim="address2"
          name="address2"
          placeholder="Apartment/Suite"
          :formatter="removeAccents"
        />
      </b-col>
      <b-col cols="12" md="12" class="mb-3">
        <label for="city" class="input-label">City*</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model.trim="answers.city"
          name="city"
          placeholder="City"
          :formatter="removeAccents"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <label for="state" class="input-label">State*</label>
        <b-form-select
          :options="stateDropdownOptions"
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model.trim="selectedState"
          name="state"
          readonly
          placeholder="State"
          :formatter="removeAccents"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <label for="zipcode" class="input-label">Zip Code*</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model.trim="answers.zipcode"
          name="zipcode"
          placeholder="Zip Code"
        />
      </b-col>
      <b-col cols="12" md="12" class="mb-3">
        <label for="phone" class="input-label">Phone*</label>
        <b-form-input
          class="fs-mask onboarding-input"
          v-model.trim="phone"
          name="phone"
          placeholder="Phone Number"
          :formatter="formatPhoneNumber"
        />
      </b-col>
    </b-row>
    <!-- <b-row class="mt-3" v-if="!loading">
      <b-col>
        <label for="allowsms" class="label-text pb-3">Winona Updates</label>
        <b-form-checkbox
          class="mb-2 checkbox-label"
          v-model="answers.allowsms"
          :value="true"
          :unchecked-value="false"
          name="allowsms"
        >
          I agree to communicate with my Winona provider via text messaging.
        </b-form-checkbox>
        <b-form-checkbox
          class="checkbox-label"
          v-model="answers.optforpromotions"
          :value="true"
          :unchecked-value="false"
          name="optforpromotions"
        >
          Yes, I'd like to occasionally receive Winona promotions.
        </b-form-checkbox>
      </b-col>
    </b-row> -->
    <b-button
      pill
      block
      variant="info"
      :disabled="buttonDisabled || loading"
      @click="submitForm"
    >
      {{ loading ? "Loading" : !saving ? "Continue" : "Saving" }}</b-button
    >

    <p class="mt-5">
      Treatment will ship by USPS. If your address cannot receive USPS postage
      please let your physician know.
    </p>

    <SideModal
      :show="showSideModal"
      @exit="hideSideModal()"
      header-class="terms-title"
      body-class="terms-body"
      feature="address"
    >
      <template #header>
        <div class="mt-5">
          <h2 class="question-title with-subtext">
            Shipping Information &amp; Address
          </h2>
          <p class="sub-text mb-0">
            Please confirm the address of where you would like your Winona
            products shipped. We ship USPS Priority unless otherwise requested.
          </p>
        </div>
      </template>
      <template #body>
        <div>
          <w-checkbox
            v-for="(address, i) in suggestedAddresses"
            :key="i"
            :id="`address${i}`"
            :value="`${i}`"
            type="radio"
            has-content
            v-model="selectedAddressSuggestion"
            label=""
          >
            <small>{{ i === 0 ? "Original" : "Suggested" }} Address</small>
            <div class="mt-2">
              <p>{{ address.street }}</p>
              <p>{{ address.city }}</p>
              <p>{{ parseState(address.state) }}</p>
              <p>{{ address.country }}</p>
              <p>{{ address.zip }}</p>
            </div>
          </w-checkbox>
          <div class="pb-5">
            <b-button
              pill
              block
              variant="info"
              @click="onUseSuggestedAddress"
              :disabled="
                buttonDisabled || loading || selectedAddressSuggestion == ''
              "
            >
              Use this Address
            </b-button>
            <b-button
              pill
              block
              variant="link"
              @click="showSideModal = false"
              :disabled="buttonDisabled || loading"
            >
              Back
            </b-button>
          </div>
        </div>
      </template>
    </SideModal>
    <div class="bg-overlay" @click="hideSideModal()"></div>
  </div>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";
import { LookUpService } from "@/services/lookup.service";
import mixins from "@/components/form/mixins";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import SideModal from "@/components/partials/SideModal.vue";

export default {
  props: {
    body: Object,
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    nonrx: {
      type: Boolean,
      default: false,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
  },
  components: {
    VueGoogleAutocomplete,
    SideModal,
  },
  mixins: [mixins],
  data() {
    return {
      loading: false,
      states: [],
      answers: {
        address1: "",
        city: "",
        country: "US",
        state: "",
        zipcode: null,
        // allowsms: true,
        // optforpromotions: true,
      },
      user: {
        firstname: "",
        lastname: "",
        birthday: "",
        phoneno: null,
      },
      phone: "",
      address2: "",
      selectedState: {},
      address: "",
      fromAutocomplete: false,
      showModal: false,
      showSideModal: false,
      selectedAddressSuggestion: "",
      suggestedAddresses: [],
      showUnavailableStateModal: false,
    };
  },
  computed: {
    stateDropdownOptions() {
      const states = [];
      for (let state of this.states) {
        states.push({ value: state, text: state.name });
      }
      return states;
    },
    shippingString() {
      return `${this.answers.address1}, ${
        this.address2 ? this.address2 + "," : ""
      } ${this.answers.city}, ${this.answers.state}`;
    },
  },
  methods: {
    verifyAuto() {
      if (this.fromAutocomplete) {
        this.submitForm();
      } else {
        this.showSideModal = true;
      }
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getAddressData(addressData, placeResultData, id) {
      this.address = addressData;
      this.fromAutocomplete = true;
      if (addressData.street_number) {
        this.answers.address1 = this.removeAccents(
          `${addressData.street_number} ${addressData.route}`
        );
      } else {
        this.answers.address1 = this.removeAccents(addressData.route);
      }
      if (addressData.country == "Puerto Rico") {
        // Manual state search override
        let selectedState = this.states.find((state) => state.id == "PR");
        this.selectedState = selectedState;
      } else {
        let selectedState = this.states.find(
          (state) => state.id == addressData.administrative_area_level_1
        );

        // console.log(addressData.administrative_area_level_1);
        this.selectedState = selectedState;
      }
      this.answers.city = addressData.locality
        ? this.removeAccents(addressData.locality)
        : "";
      this.answers.zipcode = addressData.postal_code
        ? addressData.postal_code
        : "";
    },
    onStreetAddressChange(val) {
      this.$refs.streetAddress.update(
        this.removeAccents(this.answers.address1)
      );
    },
    onStreetAddressInputChange(val) {
      this.answers.address1 = this.removeAccents(
        this.$refs.streetAddress.autocompleteText
      );
      console.log(this.answers.address1);
    },
    async submitForm() {
      this.$emit("setButtonDisable", true);

      // Check if state is available
      const selectedState = this.states.find(
        (state) => state.id == this.selectedState.id
      );
      if (!selectedState.hasOwnProperty("supported")) {
        this.showUnavailableStateModal = true;
        this.$emit("setButtonDisable", false);
        return;
      }

      if (this.address2 && this.address2 !== "")
        this.answers["address2"] = this.address2;
      if (this.nonrx) {
        let userAnswer = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          phoneno: this.phone,
        };
        try {
          let userUpdate = await OnboardingService.updatePatientOnboarding(
            5.1,
            userAnswer
          );
        } catch (err) {
          this.showSideModal = false;
          const id = this.analyticsId
            ? this.analyticsId
            : "Could not get customer ID";
          const email = this.body.email
            ? this.body.email
            : "Could not get customer email";
          this.$store.dispatch("sendErrorLog", {
            step: "Checkout - Basic Info",
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit("setButtonDisable", false);
          this.$emit("showAlert", err);
          return;
        }
      }
      this.$emit("setButtonDisable", false);
      let answers = { ...this.answers };
      answers.phoneno = this.phone;

      answers.state = this.selectedState.id;
      const index = parseInt(this.selectedAddressSuggestion);
      if (this.showModal || (this.showSideModal && index === 0)) {
        answers.overriddenaddress = true;
      } else {
        this.suggestedAddresses = [
          {
            street: this.answers.address1,
            city: this.answers.city,
            state: this.answers.state,
            country: this.answers.country,
            zip: this.answers.zipcode,
          },
        ];
      }
      this.$store.commit("global/SET_MODAL_STATE", false);
      this.$emit("nextStep", answers);
    },
    hideSideModal() {
      this.suggestedAddresses = [];
      this.elementTag(null, false);
    },
    elementTag(style, show) {
      this.showSideModal = show;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    parseState(stateText, shorten) {
      if (!stateText) {
        return;
      }
      if (shorten) {
        if (stateText.length > 2) {
          const foundState = this.states.find(
            (state) => state.name == stateText
          );
          return foundState ? foundState.id : "";
        } else {
          return stateText;
        }
      }
      if (stateText.length > 2) {
        return stateText;
      } else {
        const foundState = this.states.find((state) => state.id == stateText);
        return foundState ? foundState.name : "";
      }
    },
    onUseSuggestedAddress() {
      const index = parseInt(this.selectedAddressSuggestion);
      this.$refs.streetAddress.update(this.suggestedAddresses[index]?.street);
      this.answers.address1 = this.suggestedAddresses[index]?.street;
      this.answers.city = this.suggestedAddresses[index]?.city;
      this.selectedState = this.states.find(
        (state) =>
          state.id ==
          this.parseState(this.suggestedAddresses[index]?.state, true)
      );
      this.answers.state = this.selectedState?.name;
      this.answers.zipcode = this.suggestedAddresses[index]?.zip;
      this.submitForm();
    },
    getStateName() {
      if (this.selectedState?.address?.id) {
        const state = this.states.find(
          (s) => s.id == this.selectedState?.address?.id
        );
        return state.name;
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    try {
      this.loading = true;
      const { data } = await LookUpService.getLookUp("state");
      const userData = await OnboardingService.getPatientOnboarding();
      window.analytics.track(
        "Onboarding Screen Viewed",
        {
          customerId: this.analyticsId,
          screen: "5.2-A",
          firstname: this.body.firstname,
          lastname: this.body.lastname,
          onboardingflow: this.onboardingflow,
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          ...(await this.$store.dispatch("getVoluumDetails")),
        },
        {
          referrer: await this.$store.dispatch("getEverflowDetails"),
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );
      if (userData.data.address1) {
        this.fromAutocomplete = true;
      }
      this.answers.allowsms = userData.data.allowsms;
      this.$nextTick(() => {
        this.$refs.streetAddress.update(userData.data.address1);
      });
      this.answers.address1 = userData.data.address1;
      this.address2 = userData.data.address2;
      this.answers.city = userData.data.city;
      this.answers.zipcode = userData.data.zipcode;
      this.user.firstname = userData.data.firstname;
      this.user.lastname = userData.data.lastname;
      this.states = data;
      let selectedState = this.states.find(
        (state) => state.id == userData.data.state
      );
      this.selectedState = selectedState;
      this.answers.state = this.selectedState;
      this.phone = userData.data.phoneno;
      this.user.phoneno = userData.data.phoneno;
      this.user.birthday = userData.data.birthday;
      // this.answers.optforpromotions = userData.data.optforpromotions;
      this.loading = false;
    } catch {
    } finally {
      this.loading = false;
    }
  },
  watch: {
    "user.phoneno"() {
      this.phone = this.formatPhoneNumber(this.user.phoneno);
    },
    selectedState(val) {
      this.answers.state = val.id;
    },
  },
};
</script>

<style scoped>
.shipping-info > .row > .col > label {
  margin-bottom: 0px;
}

.shipping-info > .row > .col > input {
  padding: 5px;
}
</style>

<style lang="scss" scoped>
label {
  font-size: 11px;

  // @include media-breakpoint-up(xxl) {
  //   font-size: (11px * 2) !important
  // }
}

.form-control {
  padding-left: 0;
}
</style>
