var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shipping-info" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-stacking": "",
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-3 heading color-inherit",
                  staticStyle: { "font-size": "26px", "line-height": "32px" },
                },
                [_vm._v(" We are unable to verify your address. ")]
              ),
              _c("p", { staticStyle: { "font-size": "0.8rem" } }, [
                _vm._v(_vm._s(_vm.shippingString)),
              ]),
              _c("p", { staticClass: "mb-5" }, [
                _vm._v("Are you sure you want to ship here?"),
              ]),
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "info",
                    disabled: _vm.buttonDisabled,
                  },
                  on: {
                    click: function ($event) {
                      _vm.showModal = false
                    },
                  },
                },
                [_vm._v("Update Address")]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "outline-secondary",
                    disabled: _vm.buttonDisabled,
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("Yes, it is correct")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-stacking": "",
          },
          model: {
            value: _vm.showUnavailableStateModal,
            callback: function ($$v) {
              _vm.showUnavailableStateModal = $$v
            },
            expression: "showUnavailableStateModal",
          },
        },
        [
          _c(
            "h2",
            {
              staticClass: "mb-3 heading color-inherit",
              staticStyle: { "font-size": "26px", "line-height": "32px" },
            },
            [
              _vm._v(
                " Winona is currently unavailable for " +
                  _vm._s(_vm.selectedState.name) +
                  ". "
              ),
            ]
          ),
          _c("p", [
            _vm._v(
              " Due to state-by-state medical laws, Winona is unable to ship to this state as a primary residence. "
            ),
          ]),
          _c("p", [
            _vm._v(
              "To continue, please enter residence in one of the following states:"
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.states, function (state) {
              return _c("div", { key: state.id }, [
                state.hasOwnProperty("supported")
                  ? _c("li", [_vm._v(_vm._s(state.id))])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4", offset: "8" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        variant: "outline-secondary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showUnavailableStateModal = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v(" Shipping Information & Address "),
      ]),
      _c("p", { staticClass: "sub-text" }, [
        _vm._v(
          " Please confirm the address of where you would like your Winona products shipped. We ship USPS Priority unless otherwise requested. "
        ),
      ]),
      _vm.nonrx
        ? _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "firstname" } },
                    [_vm._v("First Name*")]
                  ),
                  _c("b-form-input", {
                    staticClass: "fs-mask onboarding-input",
                    attrs: { name: "firstname", placeholder: "First Name" },
                    model: {
                      value: _vm.user.firstname,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "firstname", $$v)
                      },
                      expression: "user.firstname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "lastname" } },
                    [_vm._v("Last Name*")]
                  ),
                  _c("b-form-input", {
                    staticClass: "fs-mask onboarding-input",
                    attrs: { name: "lastname", placeholder: "Last Name" },
                    model: {
                      value: _vm.user.lastname,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "lastname", $$v)
                      },
                      expression: "user.lastname",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "address1" } },
                [_vm._v("Street Address*")]
              ),
              _c("vue-google-autocomplete", {
                ref: "streetAddress",
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  id: "map",
                  classname: "form-control",
                  placeholder: "Street Address",
                  country: ["us", "pr"],
                },
                on: {
                  placechanged: _vm.getAddressData,
                  inputChange: _vm.onStreetAddressInputChange,
                  change: _vm.onStreetAddressChange,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "address2" } },
                [_vm._v("Apartment/Suite")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  name: "address2",
                  placeholder: "Apartment/Suite",
                  formatter: _vm.removeAccents,
                },
                on: {
                  input: function ($event) {
                    _vm.fromAutocomplete = false
                  },
                },
                model: {
                  value: _vm.address2,
                  callback: function ($$v) {
                    _vm.address2 = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "address2",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "city" } },
                [_vm._v("City*")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  name: "city",
                  placeholder: "City",
                  formatter: _vm.removeAccents,
                },
                on: {
                  input: function ($event) {
                    _vm.fromAutocomplete = false
                  },
                },
                model: {
                  value: _vm.answers.city,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.answers,
                      "city",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "answers.city",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "state" } },
                [_vm._v("State*")]
              ),
              _c("b-form-select", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  options: _vm.stateDropdownOptions,
                  name: "state",
                  readonly: "",
                  placeholder: "State",
                  formatter: _vm.removeAccents,
                },
                on: {
                  input: function ($event) {
                    _vm.fromAutocomplete = false
                  },
                },
                model: {
                  value: _vm.selectedState,
                  callback: function ($$v) {
                    _vm.selectedState =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "selectedState",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "zipcode" } },
                [_vm._v("Zip Code*")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: { name: "zipcode", placeholder: "Zip Code" },
                on: {
                  input: function ($event) {
                    _vm.fromAutocomplete = false
                  },
                },
                model: {
                  value: _vm.answers.zipcode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.answers,
                      "zipcode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "answers.zipcode",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "phone" } },
                [_vm._v("Phone*")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  name: "phone",
                  placeholder: "Phone Number",
                  formatter: _vm.formatPhoneNumber,
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            pill: "",
            block: "",
            variant: "info",
            disabled: _vm.buttonDisabled || _vm.loading,
          },
          on: { click: _vm.submitForm },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.loading ? "Loading" : !_vm.saving ? "Continue" : "Saving"
              )
          ),
        ]
      ),
      _c("p", { staticClass: "mt-5" }, [
        _vm._v(
          " Treatment will ship by USPS. If your address cannot receive USPS postage please let your physician know. "
        ),
      ]),
      _c("SideModal", {
        attrs: {
          show: _vm.showSideModal,
          "header-class": "terms-title",
          "body-class": "terms-body",
          feature: "address",
        },
        on: {
          exit: function ($event) {
            return _vm.hideSideModal()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "mt-5" }, [
                  _c("h2", { staticClass: "question-title with-subtext" }, [
                    _vm._v(" Shipping Information & Address "),
                  ]),
                  _c("p", { staticClass: "sub-text mb-0" }, [
                    _vm._v(
                      " Please confirm the address of where you would like your Winona products shipped. We ship USPS Priority unless otherwise requested. "
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _vm._l(_vm.suggestedAddresses, function (address, i) {
                      return _c(
                        "w-checkbox",
                        {
                          key: i,
                          attrs: {
                            id: `address${i}`,
                            value: `${i}`,
                            type: "radio",
                            "has-content": "",
                            label: "",
                          },
                          model: {
                            value: _vm.selectedAddressSuggestion,
                            callback: function ($$v) {
                              _vm.selectedAddressSuggestion = $$v
                            },
                            expression: "selectedAddressSuggestion",
                          },
                        },
                        [
                          _c("small", [
                            _vm._v(
                              _vm._s(i === 0 ? "Original" : "Suggested") +
                                " Address"
                            ),
                          ]),
                          _c("div", { staticClass: "mt-2" }, [
                            _c("p", [_vm._v(_vm._s(address.street))]),
                            _c("p", [_vm._v(_vm._s(address.city))]),
                            _c("p", [
                              _vm._v(_vm._s(_vm.parseState(address.state))),
                            ]),
                            _c("p", [_vm._v(_vm._s(address.country))]),
                            _c("p", [_vm._v(_vm._s(address.zip))]),
                          ]),
                        ]
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "pb-5" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "info",
                              disabled:
                                _vm.buttonDisabled ||
                                _vm.loading ||
                                _vm.selectedAddressSuggestion == "",
                            },
                            on: { click: _vm.onUseSuggestedAddress },
                          },
                          [_vm._v(" Use this Address ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "link",
                              disabled: _vm.buttonDisabled || _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showSideModal = false
                              },
                            },
                          },
                          [_vm._v(" Back ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function ($event) {
            return _vm.hideSideModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }